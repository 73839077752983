<template>
  <v-container fluid full>

    
    <v-layout>
      <v-row>
        <v-col col="12">
          <h4 class="mt-5">Welcome to {{ storeDetail.companyName | ucwords }}</h4>
        </v-col>
      </v-row>
    </v-layout>

    <v-layout v-if="productLists.length >= 1">
     
      <!--Product Session-->
      <v-container class="productContainer hidden-sm-and-down">
  
        <v-row>
          <template>
            <v-col v-for="product in productLists" :key="product.id" md="4">
              <v-sheet
                height="400"
                style="border-radius:10px;"
                elevation="3"
              >
                 
                <div style="background-color:#F5F4F4;padding:5px 25px 50px 25px !important;height:200px; width:100%;">
                    <div style="height:210px; width:100%;">
                      <v-img
                        contain
                        class="productImg"
                        :src="product.productImagePath"
                        :to="'/product-detail/' + product.productId"
                    />
                    </div>
                </div>
                
                <div class="mt-3 ml-3" >
                  <h5>
                    {{ systemSetting.systemCurrency }}
                    {{ product.price | numberFormat }}
                  </h5>
                  <p style="font-size:13px;">{{ product.name }}</p>
                  <p style="font-size:12px;">{{ product.ProductCategory.name }} <span class="ml-5">Qty: {{product.quantity}}</span></p>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/product-detail/' + product.productId"
                        v-if="product.needsPrescription == false"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-cart-outline
                        </v-icon>
                        <span>Add To Cart</span>
                      </v-btn>
                      <v-btn
                        align="center"
                        color="#000000"
                        style="color:#ffffff;"
                        :to="'/prescription/dashboard/en'"
                        v-if="product.needsPrescription == true"
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-pill-multiple
                        </v-icon>
                        Prescription Drug
                      </v-btn>
                    </v-col>
                  </v-row>
                  
                </div>
              </v-sheet>
            </v-col>
          </template>
        </v-row>
      </v-container>

      <v-container class="productContainerSm hidden-md-and-up">
        <v-row class="mb-5">
          <template>
            <v-col v-for="product in productLists" :key="product.id" cols="6">
              <v-sheet
                height="220"
                style="background-color:#C4C4C4;border-radius:10px;"
              >
                <v-img
                  class="productImg"
                  width="100%"
                  height="100"
                  :src="product.productImagePath"
                  contain
                  style="background-color:#ffffff;"
                  :to="'/product-detail/' + product.productId"
                >
                </v-img>

                <div class="pa-2" style="background-color:#FAFAFA">
                  <span style="font-size:13px;">{{ product.name | ucwords }}</span
                  ><br />
                  <span style="font-size:14px;">
                    <b>{{ systemSetting.systemCurrency }} {{ product.price  | numberFormat}}</b>
                  </span>
                  <p class="mt-1">
                    <v-btn
                    class="buttonStyle"
                      align="center"
                      outlined
                      small
                      dark
                      color="#000000"
                      :to="'/product-detail/' + product.productId"
                      v-show="!product.needsPrescription"
                    >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-cart-outline
                      </v-icon>
                      Add To Cart
                    </v-btn>

                  </p>
                </div>
              </v-sheet>
            </v-col>
          </template>

        </v-row>
      </v-container>
    </v-layout>

    <v-layout v-else>
        <v-row wrap class="pa-5">
              <v-col cols="12" align="center" justify="center">
                <p>
                  <v-img
                    alt="System Logo"
                    class="shrink mr-2"
                    contain
                    :src="'img/emptyProductList.gif'"
                    transition="scale-transition"
                    width="350"
                  />
                </p>
              </v-col>
        </v-row>
    </v-layout>
    
  </v-container>
</template>

<script>
export default {
  name: "UserStore",
  data: () => ({
    allProducts: [],
    productLists: [],
    storeDetail:{},
    storeuser_id:"",
    retailerProducts:[],
    distributorProducts:[],
    systemSetting: {
      systemCurrency: "GH₵",
    },
    isAuth: null,
    userId:"",
    userRole:null,
    manufactureRole:null,
    distributorRole:null,
    retailerRole:null,
  }),

  filters: {
      ucwords(value) {
        if (!value) return '';
        value = value.toString();
        return value.replace(/\b\w/g, function (l) {
          return l.toUpperCase();
        });
      },
      numberFormat(value) {
        if (!value) return '0';
        return new Intl.NumberFormat().format(value);
      }
  },

  created() {
    this.storeuser_id = this.$route.params.storeUserId;
    this.isAuth = this.$auth.isAuthenticated();
    this.setAuthenticatedUser();
    this.getStoreProducts();
    this.getStoreDetail();
  },

 
  methods: {
    ///////Truncate a text based on the number of characters//////
    getProductContent(product) {
      let body = this.stripTags(product.description);

      return body.length > 40 ? body.substring(0, 40) + "..." : body;
    },

    stripTags(text) {
      return text.replace(/(<([^>]+)>)/gi, "");
    },

    setAuthenticatedUser() {
      this.userId = localStorage.getItem("userId");
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + this.userId)
        .then((response) => {
          if (response.data.data.Role.name == "User") {
            this.userRole = true;
          }

          if (response.data.data.Role.name == "Manufacturer") {
            this.manufactureRole = true;
          }

          if (response.data.data.Role.name == "Distributor") {
            this.distributorRole = true;
          }

          if (response.data.data.Role.name == "Retailer") {
            this.retailerRole = true;
          }

        });
    },

    getStoreProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product/user/` + this.storeuser_id)
        .then((response) => {
          this.allProducts = response.data.data;
          response.data.data.forEach(element => {
            if(element.User.Role.name == "Retailer"){
              this.productLists.push(element);
            }
          });
        });
    },

    getStoreDetail() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + this.storeuser_id)
        .then((response) => {
          if (response.data.data.Role.name == "Manufacturer") {
            this.storeDetail = response.data.data.Manufacturer;
          }

          if (response.data.data.Role.name == "Distributor") {
            this.storeDetail = response.data.data.Distributor;
          }

          if (response.data.data.Role.name == "Retailer") {
            this.storeDetail = response.data.data.Retailer;
          }
        });
    },
  },
};
</script>

<style scoped>
.priceStyle {
  width: 90px;
  height: auto;
  font-size: 12px;
  background: linear-gradient(192.88deg, #111212 13.53%, #72bd61 90.69%);
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.buttonStyle {
  margin-top: 10px;
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 10px;
}

.productContainer {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 50px;
}

.productContainerSm {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px;
}

.productImg {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 200px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}
</style>
